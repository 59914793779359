import { SeeqNames } from '@/main/app.constants.seeqnames';
import { PropertyMatchOperatorEnumToLabel } from '@/tableDefinitionEditor/columnRules/columnRule.constants';
import {
  ColumnDefinitionOutputV1,
  ColumnRuleAncestorInputV1,
  ColumnRuleAssetCreatorInputV1,
  ColumnRuleConcatInputV1,
  ColumnRuleConstantInputV1,
  ColumnRuleDescendantInputV1,
  ColumnRuleEventPropertyInputV1,
  ColumnRuleFormulaCreatorInputV1,
  ColumnRuleGetItemPropertyInputV1,
  ColumnRuleItemSearchInputV1,
  ColumnRuleOutputV1,
  ColumnRulePathInputV1,
  ColumnRulePathSearchInputV1,
  ColumnRuleSetItemPropertyInputV1,
  ColumnRuleTextExtractorInputV1,
  ColumnRuleTextReplacementInputV1,
  ColumnRuleTreePathCreatorInputV1,
} from '@/sdk';
import { ColumnTypeEnum } from '@/sdk/model/ColumnDefinitionInputV1';
import { PropertyMatchOperatorEnum } from '@/sdk/model/ColumnRuleDescendantInputV1';
import {
  ColumnInputOptions,
  ColumnRuleWithMetadata,
  EnumInputOptions,
  NumberInputOptions,
  ParameterWithMetadata,
  TextInputOptions,
} from '@/tableDefinitionEditor/columnRules/columnRuleBuilder.types';
import { TableDefinitionAccessSettings } from '@/tableDefinitionEditor/tableDefinition.types';

export const RULES_WITH_PERMISSIONS: string[] = [
  SeeqNames.MaterializedTables.Rules.FormulaCreator,
  SeeqNames.MaterializedTables.Rules.AssetCreator,
];

/**
 * This is an ordered list of all the column rules that are available to used in the UI. The order of the rules in
 * this list are the order they will appear in the UI.
 *
 * In order for a rule to be used in the UI, it must be added to this list. A complete rule configuration will require
 * the rule name and some display related metadata bout the rule, as well as a complete {@link ParameterWithMetadata}
 * for each potential parameter that the rule can take. A parameter configuration includes the parameter name and
 * display related metadata, as well as the input type for the parameter. The input type is used to determine what kind
 * of input field will be rendered in the UI for the parameter.
 *
 * The last thing each configuration will require is a function that will take a {@link ColumnRuleOutputV1} and an
 * array
 * of {@link ColumnDefinitionOutputV1} and return the input object for the rule. This function will be used to convert
 * the output of a rule to the input of a rule when editing a rule.
 *
 * The Formula Rule is a good example of a custom rule that uses its own UI component to configure the rule, rather
 * than the more general component that we use to render other rule inputs. The Formula rule and the next couple
 * rules will give added explanations for how to configure a rule.
 */
export const ColumnRulesWithMetaData: ColumnRuleWithMetadata[] = [
  // --- Formula Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.FormulaCreator,
    label: 'SCALING.RULES.FORMULA_CREATOR.NAME',
    description: 'SCALING.RULES.FORMULA_CREATOR.DESCRIPTION',
    // Note that this rule is only allowed on UUID columns
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    /** Note that these parameters are configured using some helper functions, but a parameter can also be
     *  configured declaratively, just look at the type {@link ParameterWithMetadata} and its documentation and add
     *  objects of that type to the {@link allParameters} field */
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        isMultiple: true,
        required: true,
      }),
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexDataId,
        label: 'SCALING.PROPERTIES.COLUMN_DATA_ID_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: false,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Formula,
        label: 'SCALING.PROPERTIES.FORMULA',
        placeholder: 'SCALING.PROPERTIES.FORMULA',
        required: true,
      }),
      buildCustomParameterWithMetadata({
        // this is actually a list in the API
        parameter: SeeqNames.MaterializedTables.Parameters.ParametersName,
        label: 'SCALING.PROPERTIES.PARAMETERS',
        required: true,
      }),
      // The name parameter is a standard text input field, which means the custom Formula Creator component can
      // reuse the same text input component that is used for other text parameters
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Name,
        label: 'SCALING.PROPERTIES.NAME',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
      }),
      // The description parameter is a standard text input field, like Name, but note that it is allowed to be
      // resizable and that this parameter is NOT required. The required field should indicate whether the api
      // expects this filed to be reuired or not.
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Description,
        label: 'SCALING.PROPERTIES.DESCRIPTION',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        isResizable: true,
        required: false,
      }),
      // The following parameters are currently not exposed to the user, and so they are built as custom components
      // right now. If we decide to expose them to the user, we can change the input type to a standard input type
      // or to match a new input type that we create for them.
      buildDefaultScopedToParameterWithMetadata(),
      buildDefaultIdentityIdParameterWithMetadata(),
      buildDefaultPermissionStringParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      formulaOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
      { scopedTo }: TableDefinitionAccessSettings = {},
    ): ColumnRuleFormulaCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(formulaOutput, otherColumns);
      return {
        columnIndexes,
        description: formulaOutput.arguments.description,
        formula: formulaOutput.arguments.formula,
        // @ts-ignore - TODO CRAB-41437 identityId is a restricted parameter currently
        //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
        //  uneditable. This is a quick fix until we unrestrict these arguments
        identityId: undefined,
        // @ts-ignore - see above explanation
        permissionString: undefined,
        name: formulaOutput.arguments.name,
        parameters: formulaOutput.arguments.parameters.split('|'),
        scopedTo,
      };
    },
  },
  // --- Item Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.GetItemProperty,
    label: 'SCALING.RULES.ITEM_PROPERTY.NAME',
    description: 'SCALING.RULES.ITEM_PROPERTY.DESCRIPTION',
    // This rule is allowed on any column type
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [
      // This is a columnDropdown input field that is required. It is used to select the column that we are getting the
      // property from
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
      }),
      // This is a text input field that is required. It is used to specify the property name that we are fetching
      buildDefaultPropertyNameParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      itemPropertyOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleGetItemPropertyInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(itemPropertyOutput, otherColumns);
      return {
        columnIndex,
        propertyName: itemPropertyOutput.arguments.propertyName,
      };
    },
  },
  // --- Ancestor ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Ancestor,
    label: 'SCALING.RULES.ANCESTOR.NAME',
    description: 'SCALING.RULES.ANCESTOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 1,
        required: true,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      ancestorOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleAncestorInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(ancestorOutput, otherColumns);
      return {
        columnIndex,
        level: Number(ancestorOutput.arguments?.level),
      };
    },
  },
  // --- Descendant ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Descendant,
    label: 'SCALING.RULES.DESCENDANT.NAME',
    description: 'SCALING.RULES.DESCENDANT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        required: true,
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
      }),
      buildDefaultPropertyNameParameterWithMetadata(),
      buildEnumDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.PropertyMatchOperator,
        label: 'SCALING.PROPERTIES.PROPERTY_MATCH_OPERATOR',
        required: true,
        enumOptions: (Object.values(PropertyMatchOperatorEnum) as PropertyMatchOperatorEnum[]).map((enumValue) => {
          const label = PropertyMatchOperatorEnumToLabel[enumValue];
          const value = enumValue.toString();
          return { value, label };
        }),
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.PropertyValue,
        label: 'SCALING.PROPERTIES.PROPERTY_VALUE',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 1,
        required: false,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ItemType,
        label: 'SCALING.PROPERTIES.ITEM_TYPE',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      descendantOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleDescendantInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(descendantOutput, otherColumns);
      return {
        columnIndex,
        itemType: descendantOutput.arguments.itemType,
        propertyMatchOperator: descendantOutput.arguments.propertyMatchOperator as unknown as PropertyMatchOperatorEnum,
        propertyName: descendantOutput.arguments.propertyName,
        propertyValue: descendantOutput.arguments.propertyValue,
      };
    },
  },
  // --- Constant ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Constant.BaseConstant,
    label: 'SCALING.RULES.CONSTANT.NAME',
    description: 'SCALING.RULES.CONSTANT.DESCRIPTION',
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [
      {
        parameter: SeeqNames.MaterializedTables.Parameters.Constant,
        label: 'SCALING.PROPERTIES.CONSTANT',
        input: {
          type: 'text', // this actually depends on the column type
          placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        },
        required: true,
      },
    ],
    columnRuleOutputToColumnRuleInput(constantOutput: ColumnRuleOutputV1): ColumnRuleConstantInputV1 {
      return {
        [SeeqNames.MaterializedTables.Rules.Constant.StringConstant]: {
          constant: constantOutput.arguments['stringValue'],
        },
        [SeeqNames.MaterializedTables.Rules.Constant.NumericConstant]: {
          constant: Number(constantOutput.arguments['numericValue']),
        },
        [SeeqNames.MaterializedTables.Rules.Constant.BooleanConstant]: {
          constant: constantOutput.arguments['booleanValue'] === 'true', // coerce to boolean
        },
        [SeeqNames.MaterializedTables.Rules.Constant.UUIDConstant]: { constant: constantOutput.arguments['uuidValue'] },
        [SeeqNames.MaterializedTables.Rules.Constant.TimestampConstant]: {
          constant: constantOutput.arguments['timestampValue'],
        },
      }[constantOutput.rule] as ColumnRuleConstantInputV1;
    },
  },
  // --- Concat ---
  {
    rule: SeeqNames.MaterializedTables.Rules.ConcatColumns,
    label: 'SCALING.RULES.CONCAT.NAME',
    description: 'SCALING.RULES.CONCAT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        isMultiple: true,
        required: true,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      concatOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleConcatInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(concatOutput, otherColumns);
      return {
        columnIndexes,
      };
    },
  },
  // --- Path ---
  {
    rule: SeeqNames.MaterializedTables.Rules.Path,
    label: 'SCALING.RULES.PATH.NAME',
    description: 'SCALING.RULES.PATH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
      }),
      buildNumberParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Level,
        label: 'SCALING.PROPERTIES.LEVEL',
        minimum: 0,
        required: true,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Separator,
        label: 'SCALING.PROPERTIES.SEPARATOR',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      pathOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRulePathInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(pathOutput, otherColumns);
      return {
        columnIndex,
        level: Number(pathOutput.arguments?.level),
        separator: pathOutput.arguments.separator,
      };
    },
  },
  // --- Tree Path Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TreePathCreator,
    label: 'SCALING.RULES.TREE_PATH_CREATOR.NAME',
    description: 'SCALING.RULES.TREE_PATH_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        isMultiple: true,
        required: true,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Separator,
        label: 'SCALING.PROPERTIES.SEPARATOR',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      treePathOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTreePathCreatorInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(treePathOutput, otherColumns);
      return {
        columnIndexes,
        separator: treePathOutput.arguments.separator,
      };
    },
  },
  // --- Asset Creator ---
  {
    rule: SeeqNames.MaterializedTables.Rules.AssetCreator,
    label: 'SCALING.RULES.ASSET_CREATOR.NAME',
    description: 'SCALING.RULES.ASSET_CREATOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Description,
        label: 'SCALING.PROPERTIES.DESCRIPTION',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        isResizable: true,
        required: false,
      }),
      buildDefaultScopedToParameterWithMetadata(),
      buildDefaultIdentityIdParameterWithMetadata(),
      buildDefaultPermissionStringParameterWithMetadata(),
      buildBooleanParametersWithMetaData({
        parameter: SeeqNames.MaterializedTables.Parameters.IsRoot,
        label: 'SCALING.PROPERTIES.ROOT',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      assetOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
      { scopedTo }: TableDefinitionAccessSettings = {},
    ): ColumnRuleAssetCreatorInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(assetOutput, otherColumns);
      return {
        columnIndex,
        description: assetOutput.arguments.description,
        isRoot: assetOutput.arguments.isRoot === 'true',
        // @ts-ignore - TODO CRAB-41437 identityId is a restricted parameter currently
        //  PermissionString and identityId are required together on the backend, so if only one is set, the column is
        //  uneditable. This is a quick fix until we unrestrict these arguments
        identityId: undefined,
        // @ts-ignore - see above explanation
        permissionString: undefined,
        scopedTo,
      };
    },
  },
  // --- Event Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.EventProperty,
    label: 'SCALING.RULES.EVENT_PROPERTY.NAME',
    description: 'SCALING.RULES.EVENT_PROPERTY.DESCRIPTION',
    allowedOnColumnTypes: [
      ColumnTypeEnum.UUID,
      ColumnTypeEnum.TEXT,
      ColumnTypeEnum.BOOLEAN,
      ColumnTypeEnum.TIMESTAMPTZ,
      ColumnTypeEnum.NUMERIC,
    ],
    allParameters: [buildDefaultPropertyNameParameterWithMetadata()],
    columnRuleOutputToColumnRuleInput: (ruleOutput) =>
      ({ propertyName: ruleOutput.arguments.propertyName } as ColumnRuleEventPropertyInputV1),
  },
  // --- Set Item Property ---
  {
    rule: SeeqNames.MaterializedTables.Rules.SetItemProperty,
    label: 'SCALING.RULES.SET_ITEM_PROPERTY.NAME',
    description: 'SCALING.RULES.SET_ITEM_PROPERTY.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT], // Since this rule currently only accepts a text property
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexItem,
        label: 'SCALING.PROPERTIES.COLUMN_ITEM_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
        required: true,
      }),

      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexValue,
        label: 'SCALING.PROPERTIES.COLUMN_TEXT_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
      }),
      buildDefaultPropertyNameParameterWithMetadata(),
    ],
    columnRuleOutputToColumnRuleInput(
      setItemPropertyOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleSetItemPropertyInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(setItemPropertyOutput, otherColumns);
      // for this rule, the first input is the UUID column we are adding a property to, and the second input is the text
      // column we are getting the property value from
      const columnIndexItem = columnIndexes[0];
      const columnIndexValue = columnIndexes[1];
      return {
        columnIndexItem,
        columnIndexValue,
        propertyName: setItemPropertyOutput.arguments.propertyName,
      };
    },
  },
  // --- Path Search ---
  {
    rule: SeeqNames.MaterializedTables.Rules.PathSearch,
    label: 'SCALING.RULES.PATH_SEARCH.NAME',
    description: 'SCALING.RULES.PATH_SEARCH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Separator,
        label: 'SCALING.PROPERTIES.SEPARATOR',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      pathSearchOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRulePathSearchInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(pathSearchOutput, otherColumns);

      return {
        columnIndex,
        separator: pathSearchOutput.arguments.separator,
      };
    },
  },
  // --- Text Extractor ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TextExtractor,
    label: 'SCALING.RULES.TEXT_EXTRACTOR.NAME',
    description: 'SCALING.RULES.TEXT_EXTRACTOR.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndex,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Regex,
        label: 'SCALING.PROPERTIES.REGEX',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
        additionalInfo: {
          link: 'https://regex101.com/',
        },
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      textExtractorOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTextExtractorInputV1 {
      const columnIndex = getColumnIndexForRuleWithSingleInputColumn(textExtractorOutput, otherColumns);
      return {
        columnIndex,
        regex: textExtractorOutput.arguments.regex,
      };
    },
  },
  // --- Text Replacement ---
  {
    rule: SeeqNames.MaterializedTables.Rules.TextReplacement,
    label: 'SCALING.RULES.TEXT_REPLACEMENT.NAME',
    description: 'SCALING.RULES.TEXT_REPLACEMENT.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.TEXT],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexSourceText,
        label: 'SCALING.PROPERTIES.COLUMN_SOURCE_TEXT',
        allowedColumnInputTypes: [ColumnTypeEnum.TEXT],
        required: true,
      }),

      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Regex,
        label: 'SCALING.PROPERTIES.REGEX',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: true,
        additionalInfo: {
          link: 'https://regex101.com/',
        },
      }),

      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexReplacement,
        label: 'SCALING.PROPERTIES.COLUMN_REPLACEMENT_TEXT',
        allowedColumnInputTypes: [
          ColumnTypeEnum.TEXT,
          ColumnTypeEnum.BOOLEAN,
          ColumnTypeEnum.NUMERIC,
          ColumnTypeEnum.TIMESTAMPTZ,
          ColumnTypeEnum.UUID,
        ],
        required: false,
      }),

      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.Replacement,
        label: 'SCALING.PROPERTIES.REPLACEMENT_TEXT',
        placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
        required: false,
      }),

      buildBooleanParametersWithMetaData({
        parameter: SeeqNames.MaterializedTables.Parameters.RemoveNonMatches,
        label: 'SCALING.PROPERTIES.REMOVE_NON_MATCHES',
        required: true,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      textReplacementOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleTextReplacementInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(textReplacementOutput, otherColumns);
      return {
        columnIndexSourceText: columnIndexes[0],
        columnIndexReplacement: columnIndexes[1],
        regex: textReplacementOutput.arguments.regex,
        replacement: textReplacementOutput.arguments.replacement,
        removeNonMatches: textReplacementOutput.arguments.removeNonMatches === 'true',
      };
    },
  },
  // --- Item Search ---
  {
    rule: SeeqNames.MaterializedTables.Rules.ItemSearch,
    label: 'SCALING.RULES.ITEM_SEARCH.NAME',
    description: 'SCALING.RULES.ITEM_SEARCH.DESCRIPTION',
    allowedOnColumnTypes: [ColumnTypeEnum.UUID],
    allParameters: [
      buildColumnDropdownParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ColumnIndexes,
        label: 'SCALING.PROPERTIES.COLUMN_INPUT',
        required: true,
        isMultiple: true,
        allowedColumnInputTypes: [ColumnTypeEnum.UUID],
      }),
      buildEnumDropdownParameterWithMetadata({
        parameter: 'propertyMatchOperators',
        label: 'SCALING.PROPERTIES.PROPERTY_MATCH_OPERATOR',
        required: true,
        isMultiple: true,
        enumOptions: [],
      }),
      buildEnumDropdownParameterWithMetadata({
        parameter: 'propertyNames',
        label: 'SCALING.PROPERTIES.PROPERTY_NAME',
        required: true,
        isMultiple: true,
        enumOptions: [],
      }),
      buildTextParameterWithMetadata({
        parameter: SeeqNames.MaterializedTables.Parameters.ItemType,
        label: 'SCALING.PROPERTIES.ITEM_TYPE',
        placeholder: 'SCALING.PARAMETER_OPTIONAL_INPUT_PLACEHOLDER',
        required: false,
      }),
    ],
    columnRuleOutputToColumnRuleInput(
      itemSearchOutput: ColumnRuleOutputV1,
      otherColumns: ColumnDefinitionOutputV1[],
    ): ColumnRuleItemSearchInputV1 {
      const columnIndexes = getColumnIndexesForRuleWithMultipleInputColumns(itemSearchOutput, otherColumns);

      return {
        columnIndexes,
        propertyNames: itemSearchOutput.arguments.propertyNames.split('|'),
        propertyMatchOperators: itemSearchOutput.arguments.propertyMatchOperators.split('|'),
        itemType: itemSearchOutput.arguments.itemType,
      };
    },
  },
];

/**
 * If you configure a parameter with this parameter builder, you will get a text input field in the UI.
 */
function buildTextParameterWithMetadata(
  metadata: Omit<TextInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, placeholder, isResizable, required, additionalInfo } = metadata;
  return {
    parameter,
    label,
    additionalInfo,
    input: {
      type: 'text',
      placeholder,
      isResizable,
    },
    required,
  };
}

/**
 * This is useful for a parameter that is either a parameter not exposed through the UI because it is populated
 * through a custom UI (like 'parameters' in the formula creator rule)
 */
function buildCustomParameterWithMetadata(metadata: Omit<ParameterWithMetadata, 'input'>): ParameterWithMetadata {
  const { parameter, label, required } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'custom',
    },
    required,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a number input field in the UI.
 */
function buildNumberParameterWithMetadata(
  metadata: Omit<NumberInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, minimum, required } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'number',
      minimum,
    },
    required,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a column dropdown input field in the UI
 * that is populated only by existing columns whose type is contained in {@link allowedColumnInputTypes}. If the
 * parameter in question can take multiple columns as input, like 'columnIndexes', set {@link isMultiple} to true.
 */
function buildColumnDropdownParameterWithMetadata(
  metadata: Omit<ColumnInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, allowedColumnInputTypes, isMultiple, required } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'columnDropdown',
      allowedColumnInputTypes,
      isMultiple,
    },
    required,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a dropdown input field in the UI populated
 * with the options in {@link enumOptions}.
 */
function buildEnumDropdownParameterWithMetadata(
  metadata: Omit<EnumInputOptions, 'type'> & Omit<ParameterWithMetadata, 'input'>,
): ParameterWithMetadata {
  const { parameter, label, enumOptions, isMultiple, required } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'enumDropdown',
      enumOptions,
      isMultiple,
    },
    required,
  };
}

/**
 * If you configure a parameter with this parameter builder, you will get a boolean input field in the UI.
 */
function buildBooleanParametersWithMetaData(metadata: Omit<ParameterWithMetadata, 'input'>): ParameterWithMetadata {
  const { parameter, label, required } = metadata;
  return {
    parameter,
    label,
    input: {
      type: 'boolean',
    },
    required,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.PropertyName}, you will get a text input field in
 * the UI with the default label and placeholder for ${@link SeeqNames.MaterializedTables.Parameters.PropertyName}.
 */
function buildDefaultPropertyNameParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.PropertyName,
    label: 'SCALING.PROPERTIES.PROPERTY_NAME',
    input: {
      type: 'text',
      placeholder: 'SCALING.PARAMETER_INPUT_PLACEHOLDER',
    },
    required: true,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.ScopeTo}, this will hide the field from the user.
 */
function buildDefaultScopedToParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.ScopedTo,
    label: 'SCALING.PROPERTIES.SCOPED_TO',
    input: {
      type: 'custom', // this is actually a UUID in the API
    },
    required: false,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.IdentityId}, this will hide the field from the user.
 */
function buildDefaultIdentityIdParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.IdentityId,
    label: 'SCALING.PROPERTIES.IDENTITY_ID',
    input: {
      type: 'custom', // this is actually a UUID in the API
    },
    required: false,
  };
}

/**
 * Useful only for ${@link SeeqNames.MaterializedTables.Parameters.PermissionString}, this will hide the field from the
 * user.
 */
function buildDefaultPermissionStringParameterWithMetadata(): ParameterWithMetadata {
  return {
    parameter: SeeqNames.MaterializedTables.Parameters.PermissionString,
    label: 'SCALING.PROPERTIES.PERMISSION_STRING',
    input: {
      type: 'custom',
    },
    required: false,
  };
}

function getColumnIndexForRuleWithSingleInputColumn(
  descendantOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
) {
  const columnInputId = descendantOutput.inputs?.[0];
  const columnIndex = otherColumns.findIndex((columnDef) => columnDef.id === columnInputId);
  return columnIndex + 1; // 1-indexed
}

function getColumnIndexesForRuleWithMultipleInputColumns(
  ruleOutput: ColumnRuleOutputV1,
  otherColumns: ColumnDefinitionOutputV1[],
) {
  const columnInputIds = ruleOutput.inputs || [];
  return columnInputIds.map((columnId) => {
    const columnIndex = otherColumns.findIndex((columnDef) => columnDef.id === columnId);

    return columnIndex + 1; // 1-indexed
  });
}
