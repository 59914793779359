import { ValueOf } from '@/utilities.types';
import enJson from 'resources/i18n/en.json?url';
import esLAJson from 'resources/i18n/es-LA.json?url';
import frFRJson from 'resources/i18n/fr-FR.json?url';
import deDEJson from 'resources/i18n/de-DE.json?url';
import itITJson from 'resources/i18n/it-IT.json?url';
import jaJPJson from 'resources/i18n/ja-JP.json?url';
import koKOJson from 'resources/i18n/ko-KO.json?url';
import ptBRJson from 'resources/i18n/pt-BR.json?url';
import zhCNJson from 'resources/i18n/zh-CN.json?url';

const momentLocale_nl = () => import('moment/dist/locale/nl');
const momentLocale_enGB = () => import('moment/dist/locale/en-gb');
const momentLocale_enAU = () => import('moment/dist/locale/en-au');
const momentLocale_enCA = () => import('moment/dist/locale/en-ca');
const momentLocale_enIE = () => import('moment/dist/locale/en-ie');
const momentLocale_enIN = () => import('moment/dist/locale/en-in');
const momentLocale_enNZ = () => import('moment/dist/locale/en-nz');
const momentLocale_enSG = () => import('moment/dist/locale/en-sg');
const momentLocale_es = () => import('moment/dist/locale/es');
const momentLocale_fr = () => import('moment/dist/locale/fr');
const momentLocale_frCA = () => import('moment/dist/locale/fr-ca');
const momentLocale_frCH = () => import('moment/dist/locale/fr-ch');
const momentLocale_de = () => import('moment/dist/locale/de');
const momentLocale_it = () => import('moment/dist/locale/it');
const momentLocale_ja = () => import('moment/dist/locale/ja');
const momentLocale_ko = () => import('moment/dist/locale/ko');
const momentLocale_pt = () => import('moment/dist/locale/pt');
const momentLocale_zh = () => import('moment/dist/locale/zh-cn');

/**
 *  Supported languages (string translations) within the app.
 *  Changing the selected language will not affect date formatting.
 *  For that, see `MOMENT_LOCALE_DATA`.
 **/
export const LOCALES = {
  EN: 'en',
  ES: 'es-LA',
  DE: 'de-DE',
  FR: 'fr-FR',
  IT: 'it-IT',
  JA: 'ja-JP',
  KO: 'ko-KO',
  PT: 'pt-BR',
  ZH: 'zh-CN',
} as const;
export type LocaleKey = ValueOf<typeof LOCALES>;

/**
 * For the language selector in the user profile modal, we do not want to translate the text since we want each
 * language label to be in its respective language.
 */
type LocaleData = { key: LocaleKey; text: string; translationPath: string };
export const LOCALE_DATA: LocaleData[] = [
  { key: LOCALES.EN, text: 'English', translationPath: enJson },
  { key: LOCALES.ES, text: 'Español', translationPath: esLAJson },
  { key: LOCALES.FR, text: 'Français', translationPath: frFRJson },
  { key: LOCALES.DE, text: 'Deutsch', translationPath: deDEJson },
  { key: LOCALES.IT, text: 'Italiano', translationPath: itITJson },
  { key: LOCALES.JA, text: '日本語', translationPath: jaJPJson },
  { key: LOCALES.KO, text: '한국어', translationPath: koKOJson },
  { key: LOCALES.PT, text: 'Português', translationPath: ptBRJson },
  { key: LOCALES.ZH, text: '普通话', translationPath: zhCNJson },
];

/**
 * Used for setting  Moment's locale to match the user's browser settings so that dates
 * appear formatted in an expected way.
 *
 * Example: users with their browser set to UK English are in the en-gb locale
 * and expect to see "May 22nd, 2024" as "22/5/2024".
 **/

type MomentLocaleData = { [key in string]: any };
export const MOMENT_LOCALE_DATA: MomentLocaleData = {
  'en-gb': momentLocale_enGB,
  'en-au': momentLocale_enAU,
  'en-ca': momentLocale_enCA,
  'en-ie': momentLocale_enIE,
  'en-in': momentLocale_enIN,
  'en-nz': momentLocale_enNZ,
  'en-sg': momentLocale_enSG,
  'es': momentLocale_es,
  'fr': momentLocale_fr,
  'fr-ca': momentLocale_frCA,
  'fr-ch': momentLocale_frCH,
  'de': momentLocale_de,
  'it': momentLocale_it,
  'ja': momentLocale_ja,
  'ko': momentLocale_ko,
  'nl': momentLocale_nl,
  'pt': momentLocale_pt,
  'zh-cn': momentLocale_zh,
  'zh-hk': momentLocale_zh,
  'zh-mo': momentLocale_zh,
  'zh-tw': momentLocale_zh,
} as const;
