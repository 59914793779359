/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.0.0-v202409231713-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

export interface ContentInputV1 {
    /**
     * Whether the content should be archived
     */
    "archived"?: boolean;
    /**
     * The content's asset selection
     */
    "assetSelectionId"?: string;
    /**
     * The content's date range
     */
    "dateRangeId"?: string;
    /**
     * The content's description
     */
    "description"?: string;
    /**
     * The content's desired height
     */
    "height": number;
    /**
     * Whether uncertainty indicators should be hidden.
     */
    "hideUncertainty"?: boolean;
    /**
     * The content's name
     */
    "name": string;
    /**
     * Whether the content is for a react component.
     */
    "react"?: boolean;
    /**
     * The content's report
     */
    "reportId"?: string;
    /**
     * The content's desired scale. A value greater than 1 will increase the size of elements within the screenshot. A value less than 1 will shrink the size of elements within the screenshot.
     */
    "scale"?: number;
    /**
     * The content's desired selector
     */
    "selector"?: string;
    /**
     * The summary type for this screenshot if a summary is being applied. One of DISCRETE, AUTO, NONE
     */
    "summaryType"?: SummaryTypeEnum;
    /**
     * The value for the given summary type. If discrete, a time + unit pairing (1min, 2days). If auto, a fixed value (0-10).
     */
    "summaryValue"?: string;
    /**
     * The IANA name of the content's desired time zone
     */
    "timezone"?: string;
    /**
     * The content's desired width
     */
    "width": number;
    /**
     * The content's source worksheet
     */
    "worksheetId": string;
    /**
     * The content's source workstep
     */
    "workstepId": string;
}

export enum SummaryTypeEnum {
    DISCRETE = 'DISCRETE' as any,
    AUTO = 'AUTO' as any,
    NONE = 'NONE' as any
}
