import _ from 'lodash';
import i18next from 'i18next';
import Backend from 'i18next-chained-backend';
import { initReactI18next } from 'react-i18next';
import HttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import HttpCodes from 'http-status-codes';
import { flux } from '@/core/flux.module';
import Highcharts from 'other_components/highcharts';
import { LOCALE_DATA, LocaleKey, LOCALES, MOMENT_LOCALE_DATA } from '@/utilities/i18n.constants';
import { i18nHostname } from '@/services/systemConfiguration.utilities';
import moment from 'moment-timezone';
import { LangOptions } from 'highcharts';

export async function init18n() {
  const browserLanguage = getBrowserLanguage().toLowerCase();
  if (browserLanguage !== 'en') {
    const path = MOMENT_LOCALE_DATA[browserLanguage];
    if (path) {
      path().then((localeResponse: { default: { _config: moment.LocaleSpecification | null } }) => {
        moment.defineLocale(browserLanguage, localeResponse.default._config);
        moment.locale(browserLanguage);
      });
    }
  }

  const useI18nServer = !_.isNil(i18nHostname());
  let manifest: Record<string, string> = {};
  const remoteBackend: HttpBackendOptions = {
    loadPath: async ([language]: string[]) => {
      manifest = await fetchI18nManifest(`https://${i18nHostname()}`, manifest);
      return manifest[language];
    },
  };
  const localBackend: HttpBackendOptions = {
    loadPath: ([language]: string[]) => _.find(LOCALE_DATA, ({ key }) => key === language)!.translationPath,
  };

  i18next
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: 'en',
      fallbackLng: 'en',
      compatibilityJSON: 'v3',
      supportedLngs: _.values(LOCALES),
      nonExplicitSupportedLngs: false,
      interpolation: {
        skipOnVariables: true,
        escapeValue: false,
      },
      backend: {
        backends: useI18nServer ? [HttpBackend, HttpBackend] : [HttpBackend],
        backendOptions: useI18nServer ? [remoteBackend, localBackend] : [localBackend],
      },
      react: {
        useSuspense: true,
      },
    });
}

/**
 * For use with the external i18n server. Upon first run it fetches manifest file (created as part of the build
 * process by Vite) and parses it into a map of language code to file URL.
 *
 * @param i18nUrl The URL of the i18n server
 * @param manifest The current map holding the language code to file URL
 * @return The populated manifest file, or undefined if an error occurs
 */
export async function fetchI18nManifest(i18nUrl: string, manifest: Record<string, string>) {
  if (!_.isEmpty(manifest)) {
    return manifest;
  }

  try {
    const response = await fetch(`${i18nUrl}/manifest-i18n.json`, { method: 'GET' });
    if (response.status !== HttpCodes.OK) {
      throw new Error(`Failed to fetch i18n manifest: ${response.status}`);
    }

    const manifestData: Record<string, { file: string }> = await response.json();
    return _.transform(
      manifestData,
      (result, value, key) => {
        result[key.replace(/.*\/(.*?).json/, '$1')] = `${i18nUrl}/${value.file}`;
      },
      {} as Record<string, string>,
    );
  } catch (e) {
    return manifest;
  }
}

/**
 * Return only the language portion of the locale string. For example, a locale of "pt-BR" would return "pt".
 *
 * @param locale - The locale
 */
export function getLanguage(locale: LocaleKey) {
  return locale.slice(0, 2);
}

export function getBrowserLanguage() {
  return navigator.language || (navigator as any).userLanguage;
}

/**
 * Switches to the provided language. This function also sets the moment locale and loads the appropriate
 * Highcharts translations file, and makes sure that the Tools are properly translated.
 *
 * @param locale - The locale key
 */
export async function switchLanguage(locale: LocaleKey) {
  await i18next.changeLanguage(locale);
  Highcharts.setOptions({ lang: i18next.t('HIGHCHARTS', { returnObjects: true }) as LangOptions });
  // NOTE: we do not want to set a new locale for moment as users are used to having it use their browser
  // locale (CRAB-24568)
  flux.dispatch('INVESTIGATE_TRIGGER_INVESTIGATE_TOOL_TRANSLATION');
  flux.dispatch('SET_USER_LANGUAGE', locale);
}
